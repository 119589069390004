import Vue from 'vue';
import config, { handleInitialConfig } from '../config';
import errorTracker from './plugins/errorTracker';
import App from './App';
import router from './router';
import store from './store';
import integrator from './plugins/seven-gravity-gateway/slave';
import vuetify from './plugins/vuetify';
import './styles/main.scss';

// Initialize error tracker at the earliest app point
if (config.errorTracker.active) {
  errorTracker.init(Vue);
}

Vue.config.productionTip = false;

// Handles env variables and qp overrides
handleInitialConfig();

const initializeApp = (initialData) => {
  const user = {
    token: initialData.user && initialData.user.auth.token,
  };

  // Set user info
  if (user.token) store.dispatch('setUser', user);

  // Mount app
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
};

if (!config.integration.active) {
  initializeApp({});
} else {
  // Sets up integration service
  integrator.init().then((message) => {
    if (message.data.route) {
      router.replace(message.data.route).then(() => {
        initializeApp(message.data);
      });
    } else {
      initializeApp(message.data);
    }
  });
}
