import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import navigationGuards from './navigationGuards';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "home" */ '@/layouts/Base'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '@/pages/Home'),
        },
        {
          path: 'reports',
          name: 'reports',
          redirect: 'reports/book-of-betting/admin',
          component: () => import(/* webpackChunkName: "reports" */ '@/pages/Reports'),
          children: [
            {
              path: 'book-of-betting/:type',
              name: 'bookOfBetting',
              component: () => import(/* webpackChunkName: "bookOfBetting" */ '@/pages/BookOfBetting'),
            },
            {
              path: 'payments-traffic',
              name: 'paymentsReport',
              component: () => import(/* webpackChunkName: "paymentsReport" */ '@/pages/PaymentsReport'),
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => navigationGuards.appBootGuard(store, next));

export default router;
