import { gravityApi, productApi } from '@/api';
import types from './mutationTypes';
import config from '../../config';

export default {
  bootstrapApp({ commit, dispatch }) {
    commit(types.SET_LANGUAGE, config.language);
    return Promise.all([
      dispatch('loadProductList'),
      dispatch('loadSettings'),
    ]);
  },
  async loadProductList({ commit }) {
    const products = await productApi.getProductList();

    if (!products.error) {
      commit(types.SET_PRODUCTS, products);
    }
  },
  async loadSettings({ commit }) {
    const settings = await gravityApi.getApplicationSettings();

    if (!settings.error) {
      commit(types.SET_SETTINGS, settings.config);
    }
  },
  setUser({ commit }, payload) {
    commit(types.SET_USER, payload);
  },
};
