export default {
  allowedExportTypes: {
    paymentsReport: ['pdf'],
  },
  availableProducts: [],
  errorNotification: {},
  language: 'bs',
  reportTypes: [ // TODO combine with menu-items after definition is complete?
    {
      id: 1,
      priority: 1,
      key: 'bookOfBettingAdmin',
      title: 'Knjiga klađenja Admin',
      to: '/reports/book-of-betting/admin',
    },
    {
      id: 2,
      priority: 2,
      key: 'bookOfBettingWeb',
      title: 'Knjiga klađenja Web',
      to: '/reports/book-of-betting/web',
    },
    {
      id: 3,
      priority: 3,
      key: 'bookOfBettingRetail',
      title: 'Knjiga klađenja Shop',
      to: '/reports/book-of-betting/shop',
    },
    {
      id: 4,
      priority: 4,
      key: 'paymentsReport',
      title: 'Uplata kladioničkih igara',
      to: '/reports/payments-traffic',
    },
  ],
  channelsFilter: [
    {
      id: 'Retail',
      name: 'Shop',
    },
    {
      id: 'Terminal',
      name: 'Terminal',
    },
  ],
  settingsLoaded: false,
  settings: {
    bookOfBettingColumns: [],
    exportTypes: [],
    regions: [],
    paginationSizes: [],
  },
  translations: {
    allColumns: 'Sve kolone',
    bookOfBettingAdmin: 'Knjiga Klađenja Admin',
    bookOfBettingRetail: 'Knjiga Klađenja Shop',
    bookOfBettingWeb: 'Knjiga Klađenja Web',
    cancelAction: 'Poništi',
    cashRegisterHoldTitle: 'Blagajna',
    channelTitle: 'Kanal',
    confirmAction: 'Potvrdi',
    genericError: 'Dogodila se greška',
    holdTitle: 'Razlika',
    locationAddressTitle: 'Adresa Objekta',
    locationDisplayIdTitle: 'Broj objekta',
    locationNameTitle: 'Naziv Objekta',
    maxRangeError: 'Molimo odaberite kraći vremenski period',
    noDataMessage: 'Nema podataka za odabrane parametre',
    operatorFullNameTitle: 'Ime prezime operatora',
    operatorUsernameTitle: 'Operativni broj radnika',
    paymentsReport: 'Promet/uplata kladioničkih igara',
    paymentTitle: 'Uplata',
    paymentTaxTitle: 'Naknada 5%',
    payoutTitle: 'Dobici bez poreza',
    payoutTaxTitle: 'Obračunati porez na dobitke 10%',
    period: 'Period',
    playerFirstNameTitle: 'Ime igrača',
    playerIdTitle: 'ID igrača',
    playerLastNameTitle: 'Prezime igrača',
    products: 'Proizvod',
    region: 'Regija',
    regionRequired: 'Odaberite regiju',
    selectedColumns: 'Označene kolone',
    ticketCountTitle: 'Broj listića',
    total: 'Ukupno',
    winningsTitle: 'Dobici (sa porezom)',
  },
  user: {},
};
