import axios from 'axios';
import config from '../../config';

const http = axios.create({
  baseURL: config.api.wallet,
  timeout: 10000,
});

export default {
  async getProductList() {
    try {
      const result = await http.get('sale/products');
      return result.data;
    } catch (e) {
      const error = e.response ? e.response.data : e;
      return {
        error: true,
        ...error,
      };
    }
  },
};
