import Vue from 'vue';
// https://vuetifyjs.com/en/customization/sass-variables/#compilation-time
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#006bf5',
        error: '#ec4234',
        success: '#00a86b',
        info: '#13aaff',
        warning: '#ffba13',
      },
    },
  },
});
