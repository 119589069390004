import GatewaySlave from '@nsftx/seven-gravity-gateway/slave';
import config from './config';
import router from '../../router';

let instance = null;

const setListeners = () => {
  router.afterEach((to) => {
    instance.sendMessage({
      action: 'Router.ChangeRoute',
      data: {
        route: to.fullPath,
      },
    });
  });
};

export default {
  /**
   *
   * @typedef SlaveLoadMessage
   * @property {'Slave.Load'} action
   * @property {Object} data
   * @property {Object} data.user
   * @property {Object} data.user.auth
   * @property {String} data.user.auth.token
   * @property {String} data.user.email
   * @property {String} data.user.firstName
   * @property {String} data.user.language
   * @property {String} data.user.lastName
   * @property {String} data.user.timezone
   * @property {String} data.user.username
   * @property {String} data.user.uuid
   * @property {String} data.route
   */

  /**
   * Initialize gateway.
   *
   * @returns {Promise.<SlaveLoadMessage>} - Returns master data if any
   */
  init() {
    return new Promise((resolve) => {
      instance = GatewaySlave({
        load: (message) => {
          resolve(message);
        },
        ...config,
      });

      setListeners();
    });
  },
  get api() {
    return instance;
  },
  sendMessage(message) {
    if (!this.api) return;

    this.api.sendMessage(message);
  },
};
