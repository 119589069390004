<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import integrator from '@/plugins/seven-gravity-gateway/slave';

export default {
  name: 'App',
  data: () => ({
    //
  }),
  mounted() {
    // Send event to parent app
    integrator.sendMessage({
      action: 'Slave.Loaded',
    });
  },
};
</script>
