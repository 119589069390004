import { differenceInCalendarDays } from 'date-fns';

export default {
  isInYearRange(range) {
    const start = range[0];
    const end = range[1];

    const daysDifference = differenceInCalendarDays(new Date(end), new Date(start));
    return daysDifference <= 366;
  },
};
