import { isObject } from 'lodash';

const isSupported = () => {
  const test = 'localStorage';

  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export default {
  isSupported: isSupported(),
  getItem(key) {
    if (!this.isSupported) return null;

    const value = localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
  removeItem(key) {
    if (!this.isSupported) return null;

    localStorage.removeItem(key);
    return this.getItem(key);
  },
  setItem(key, value) {
    if (!this.isSupported) return null;

    let valueToStore = value;
    if (isObject(value)) valueToStore = JSON.stringify(value);

    localStorage.setItem(key, valueToStore);
    return this.getItem(key);
  },
};
