import {
  capitalize, filter, map, orderBy,
} from 'lodash';

export default {
  availableProducts: (state) => (type) => {
    const typeMapper = {
      shop: 'retail',
    };

    if (!type || type === 'admin') return state.availableProducts;

    const mappedType = typeMapper[type] || type;

    return filter(
      state.availableProducts,
      (product) => product.channels.indexOf(capitalize(mappedType)) >= 0,
    );
  },
  bookOfBettingColumns: (state) => (type) => {
    const key = state.settings.bookOfBettingColumns[type] ? type : 'common';
    const translatedColumns = map(state.settings.bookOfBettingColumns[key], (item) => ({
      value: item.name,
      text: state.translations[`${item.name}Title`] || item.name,
      selected: true,
      align: item.type === 'number' ? 'end' : 'start',
      type: item.type,
    }));

    return translatedColumns;
  },
  exportTypes: (state) => (reportType = '') => {
    const { exportTypes } = state.settings;
    const allowedExportTypes = state.allowedExportTypes[reportType];

    if (allowedExportTypes) {
      return filter(exportTypes, (exportType) => allowedExportTypes.find((element) => element === exportType.name));
    }
    return exportTypes;
  },
  channelsFilter: (state) => state.channelsFilter,
  regions: (state) => state.settings.regions,
  language: (state) => state.language,
  paginationSizes: (state) => state.settings.paginationSizes,
  reportTypes: (state) => {
    const formatted = map(state.reportTypes, (item) => ({
      ...item,
      title: state.translations[item.key],
    }));

    return orderBy(formatted, ['priority']);
  },
  settings: (state) => state.settings,
  translations: (state) => state.translations,
  translation: (state) => (key) => state.translations[key],
};
