import config from '../../../config';

export default {
  /**
   * Initial gateway configuration
   *
   * @param {String} slaveId - Unique identifier of a frame
   * @param {Object} [data] - Data which will be passed to master on init phase
   * @param {Array} [allowedOrigins] - Array of allowed origins
   * @param {Boolean} [debug] - Debug setting
   * @param {String | Worker} [worker] - Web worker configuration
   * @param {Object} [eventPropagation] - Events which will be propagated to master frame
   * @param {Object} [eventListeners] - Events which are required from master frame
   */
  allowedOrigins: config.integration.allowedOrigins === '*'
    ? config.integration.allowedOrigins
    : config.integration.allowedOrigins.split(','),
  slaveId: config.integration.integrationId,
  eventPropagation: {
    click: true,
  },
};
