import { localStorage } from '@/utility';
import types from './mutationTypes';

export default {
  [types.SET_LANGUAGE](state, payload) {
    state.language = payload;
  },
  [types.SET_PRODUCTS](state, payload) {
    state.availableProducts = payload.map((item) => ({
      ...item,
      id: item.id === 'Voucher' ? 'voucher' : item.id,
    }));
  },
  [types.SET_SETTINGS](state, payload) {
    state.settings = payload;
    state.settingsLoaded = true;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = payload;
  },
  [types.SET_USER](state, payload) {
    state.user = payload;
    localStorage.setItem('user', payload);
  },
};
