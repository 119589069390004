const parseFromQuerystring = (query) => {
  const parsed = {};
  query.substring(1).replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
    parsed[window.decodeURIComponent(key)] = window.decodeURIComponent(value);
  });

  return parsed;
};

const config = {
  api: {
    gravity: process.env.VUE_APP_GRAVITY_API_BASEURL,
    reporting: process.env.VUE_APP_REPORTING_API_BASEURL,
    voucherReporting: process.env.VUE_APP_VOUCHER_REPORTING_BASEURL,
    wallet: process.env.VUE_APP_WALLET_API_BASEURL,
  },
  app: {
    gravityAppName: process.env.VUE_APP_GRAVITY_APP_NAME,
  },
  company: {
    uuid: process.env.VUE_APP_COMPANY_UUID,
  },
  errorTracker: {
    active: process.env.VUE_APP_ERROR_TRACKER_ACTIVE === 'true',
    sentryDsn: process.env.VUE_APP_SENTRY_DSN,
  },
  integration: {
    active: true,
    allowedOrigins: process.env.VUE_APP_ALLOWED_INTEGRATOR_ORIGINS,
    integrationId: process.env.VUE_APP_INTEGRATION_ID,
  },
  language: process.env.VUE_APP_DEFAULT_LANGUAGE,
};

export function handleInitialConfig() {
  // Handle query parameter overrides
  const queryConfig = parseFromQuerystring(window.location.search);

  if (!queryConfig) return;

  // Add future qp config overrides here
  config.integration.active = queryConfig.mode && queryConfig.mode === 'integration';
}

export default config;
