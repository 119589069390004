import { v4 as uuidv4 } from 'uuid';
import { localStorage } from '@/utility';
import config from '../../../config';

const reportingInterceptor = (requestConfig) => {
  const transformedConfig = requestConfig;
  const user = localStorage.getItem('user');
  const requestId = uuidv4();

  transformedConfig.headers['X-Nsft-Seven-Tenant-ID'] = config.company.uuid;
  transformedConfig.headers['X-Request-ID'] = requestId;
  transformedConfig.headers.Authorization = `Bearer ${user.token}`;

  return transformedConfig;
};

// eslint-disable-next-line
export { reportingInterceptor };
