import Vue from 'vue';
import * as Sentry from '@sentry/vue';

export default {
  init() {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
    });
    Sentry.getCurrentScope().setTag('environment', process.env.NODE_ENV);
  },
};
