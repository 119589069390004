import axios from 'axios';
import { format, formatISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { capitalize } from 'lodash';
import config from '../../config';
import { reportingInterceptor } from './interceptors';

const http = axios.create({
  baseURL: config.api.reporting,
  timeout: 10000,
});

http.interceptors.request.use(
  reportingInterceptor,
  (error) => Promise.reject(error),
);

const voucherHttp = axios.create({
  baseURL: config.api.voucherReporting,
  timeout: 10000,
});

voucherHttp.interceptors.request.use(
  reportingInterceptor,
  (error) => Promise.reject(error),
);

let controller = new AbortController();

const getHttpInstance = (product) => {
  if (product === 'voucher') return voucherHttp;

  return http;
};

export default {
  async getBookOfBetting({
    from, to, type, product, channel, page = 1, size = 20,
  }) {
    controller.abort();
    controller = new AbortController();

    const url = `betting-book/${type}/${product}`;
    const params = {
      dateFrom: formatISO(new Date(from)),
      dateTo: formatISO(new Date(to)),
      channel,
      page,
      size,
    };

    try {
      const result = await getHttpInstance(product).get(url, { signal: controller.signal, params });
      return result.data;
    } catch (e) {
      const error = e.response ? e.response.data : e;
      return {
        error: true,
        isCancelled: axios.isCancel(e),
        ...error,
      };
    }
  },
  async getBookOfBettingExport({
    columns, from, to, type, product, channel, exportType, voucherExportType,
  }) {
    const url = `betting-book/${type}/${product}/export`;
    const params = {
      dateFrom: formatISO(new Date(from)),
      dateTo: formatISO(new Date(to)),
      channel,
    };

    const headers = {
      accept: exportType,
    };

    if (columns) params.columns = columns.join(',');
    if (product === 'voucher') params.fileType = voucherExportType;

    try {
      const result = await getHttpInstance(product).get(url, {
        params,
        headers,
        responseType: 'blob',
      });
      let filename;

      if (product === 'voucher') {
        const formattedDate = format(new Date(), 'dd.MM.yyyy');
        const formattedType = capitalize(type);
        const formattedExtension = voucherExportType.toLowerCase();

        filename = `Knjiga klađenja za igru Voucher ${formattedType} ${formattedDate}.${formattedExtension}`;
      } else {
        const contentDisposition = result.headers['content-disposition'];

        filename = contentDisposition && contentDisposition.replace('attachment; filename=', '');
      }

      saveAs(result.data, filename);

      return result.data;
    } catch (e) {
      const error = e.response ? e.response.data : e;
      return {
        error: true,
        ...error,
      };
    }
  },
  async getPaymentsSummaryExport({
    region, date, exportType,
  }) {
    const url = 'payments-summary-export';

    const params = {
      region,
      date: formatISO(new Date(date)),
    };

    try {
      const result = await http.get(url, {
        headers: {
          accept: exportType,
        },
        params,
        responseType: 'blob',
      });

      const filename = result.headers['content-disposition'].replace('attachment; filename=', '');
      saveAs(result.data, filename);
      return result.data;
    } catch (e) {
      const error = e.response ? e.response.data : e;
      return {
        error: true,
        ...error,
      };
    }
  },
};
